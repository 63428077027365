import {OrbitControls, Raycasting} from "./Controls";
import {useContext, useEffect} from "react";
import {RouteFocusState, TopDownState} from "../canvas/CanvasState";
import RouteInfoPanel from "../overlay/RouteInfoPanel";
import {WallContext} from "../../contexts/WallContext";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import * as THREE from "three";
import OutlineType from "../canvas/OutlineType";
import {AppContext} from "../../AppContext";
import {BasicButton} from "../../common/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import * as config from "../../Config";
import {RouteStateType} from "../../model/User";
import {UserContext} from "../../contexts/UserContext";

export default function RouteFocusController({canvas, setSceneState, canvasState}) {
    const route = canvasState.route;
    const wall = useContext(WallContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    const user = useContext(UserContext);

    useEffect(() => {
        if (wall === null || canvas === null)
            return;

        function onControlsUpdate() {
            canvas.render();
        }

        const routeMeshes = route.holds.map(hold => hold.holdMesh);

        /**
         * Highlight other routes (while still highlighting the current one).
         */
        function onRouteHover(raycastEvent) {
            const result = raycastEvent.result;
            if (result.hit) {

                if (AppContext.Mutable.isCanvasDragging)
                    return;

                const hitRoute = result.target.object;

                if (hitRoute !== route) {
                    canvas.setOutlinedObjects(
                        OutlineType.HIGHLIGHT,
                        [...new Set([...hitRoute.holds.map(hold => hold.holdMesh), ...routeMeshes])]
                    );
                    canvas.render();
                    return;
                }
            }
            canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, routeMeshes);
            canvas.render();
        }

        const controls = new OrbitControls(
            canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation
        );

        controls.addEventListener("change", onControlsUpdate);

        let routeOrbitTarget = route.getCenter();
        let routeCameraPosition = route.getViewingPoint(wall.wallMesh, (config.MIN_ROUTE_ZOOM_DISTANCE + config.MAX_ROUTE_ZOOM_DISTANCE) / 2);
        let routeViewingDirection = route.getViewingDirection(wall.wallMesh);
        let currentHold = null;

        controls.register(
            routeOrbitTarget, routeCameraPosition, routeViewingDirection,
            config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
        );

        controls.addEventListener("control_escape_event", onZoomOut);

        function onZoomOut(event) {
            if (currentHold !== null) {
                controls.changeTarget(
                    routeOrbitTarget, routeCameraPosition, routeViewingDirection,
                    config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
                );

                currentHold = null;
            } else {
                setCanvasState(new TopDownState(route.getCenter()));
            }
        }

        function onRouteClick(raycastEvent) {
            const result = raycastEvent.result;
            const clickedRoute = result.target.object;

            let holdMesh = null;
            if (raycastEvent.type === "object_approach_click")
                holdMesh = raycastEvent.spherePart;
            else if (raycastEvent.type === "object_click")
                holdMesh = result.part;

            let hold = null;
            for (let h of route.holds) {
                if (h.holdMesh === holdMesh) {
                    hold = h;
                }
            }

            // clicking on a hold of the route zooms to it
            if (clickedRoute === route) {
                // if we're already looking at the hold, clicking at it again goes back to the entire route
                if (currentHold === hold) {
                    controls.changeTarget(
                        routeOrbitTarget, routeCameraPosition, routeViewingDirection,
                        config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
                    );

                    currentHold = null;
                } else {
                    let center = hold.getCenter();
                    let normal = route.getHoldWallNormal(wall.wallMesh, hold);

                    let cameraPosition = center.clone().addScaledVector(
                        normal,
                        (config.MIN_HOLD_ZOOM_DISTANCE + config.MAX_HOLD_ZOOM_DISTANCE) / 2
                    );

                    controls.changeTarget(
                        center, cameraPosition, normal.multiplyScalar(-1),
                        config.MIN_HOLD_ZOOM_DISTANCE, config.MAX_HOLD_ZOOM_DISTANCE,
                    );

                    currentHold = hold;
                }
            } else {
                setCanvasState(new RouteFocusState(clickedRoute));
            }
        }

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera, true);

        raycasting.addEventListener("object_hover", onRouteHover);
        raycasting.addEventListener("object_approach", onRouteHover);
        raycasting.addEventListener("object_click", onRouteClick);
        raycasting.addEventListener("object_approach_click", onRouteClick);
        raycasting.addEventListener("object_miss_click", onZoomOut);

        for (let i = 0; i < wall.routes.length; i++) {
            const otherRoute = wall.routes[i];
            const holds = otherRoute.holds;
            raycasting.addTarget(otherRoute, holds.map(hold => hold.holdMesh));
            raycasting.addApproachTarget(otherRoute, holds.map(hold => hold.holdMesh), holds.map(hold => new THREE.Sphere(hold.getCenter(),
                0.05)));
        }

        raycasting.register();

        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, routeMeshes);

        return () => {
            raycasting.unregister();
            controls.unregister();
        }
    }, [wall, route, canvas, setCanvasState]);

    useEffect(() => {
        canvas.render();
    });

    function onBackToTopView() {
        setSceneState(new TopDownState(route.getCenter()));
    }

    let routes = wall.routes;

    let categories = new Array(routes.length).fill(RouteStateType.Untouched);
    if (user.value)
        categories = user.value.getRoutesClimbingStates(routes)

    const currentIndex = routes.indexOf(route);

    const currentCategory = categories[currentIndex];
    const currentColor = RouteStateType.Color(currentCategory);

    if (currentIndex === -1) {
        // this should never happen
        throw new Error("The route object is not in the array.");
    }

    const previousIndex = (currentIndex - 1 + routes.length) % routes.length;
    const nextIndex = (currentIndex + 1) % routes.length;

    function onPreviousRoute() {
        setSceneState(new RouteFocusState(routes[previousIndex]));
    }

    function onNextRoute() {
        setSceneState(new RouteFocusState(routes[nextIndex]));
    }

    function routeButton(type, onClick) {
        return <BasicButton
            buttonColor={currentColor}
            onClick={onClick}
            className={`${type === 'left' ? 'rounded-r-full' : 'rounded-l-full'} shadow-medium absolute ${type}-0 top-1/2 -translate-y-1/2 transition-colors duration-75`}
        >
            <FontAwesomeIcon icon={type === 'left' ? faArrowLeft : faArrowRight} className="ml-0.5 text-center"/>
        </BasicButton>;
    }

    return (<>
        {routeButton('left', onPreviousRoute)}
        {routeButton('right', onNextRoute)}
        <RouteInfoPanel route={route} onClickBack={onBackToTopView}/>
    </>);
}